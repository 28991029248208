import firebase from 'firebase/app'
import 'firebase/analytics'

// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

(function( $ ) {
  $.fn.monostreamPlayer = function(options) {
    return this.each(function() {
      const PLAYBACK_URL = process.env.STREAM_URL;

      registerIVSTech(videojs);
      registerIVSQualityPlugin(videojs);
      var player = videojs('amazon-ivs-videojs', {
        techOrder: ["AmazonIVS"]
      }, () => {
        player.enableIVSQualityPlugin();
        if (process.env.STREAM_DISABLED !== '1') {
          console.log('Setting player.src');
          console.log(PLAYBACK_URL);
          player.src(PLAYBACK_URL);
        } else {
          if (process.env.STREAM_TESTING === '1' && window.location.href.indexOf('web.app') !== -1) {
            // in testing mode load video for web.app default Firebase URLs
            console.log('Testing mode');
            console.log('Setting player.src');
            console.log(PLAYBACK_URL);
            player.src(PLAYBACK_URL);
          }
        }
      });
    });
  }
  
  $(function() {
    $('#amazon-ivs-videojs').monostreamPlayer();

    $('#reloadButton').on('click', function(evt) {
      $('#formFrame').attr('src', $('#formFrame').attr('src'));
    });

    if (process.env.NODE_ENV === 'production') {
      const firebaseConfig = {
        apiKey: "AIzaSyAImi9D2ppYGRT-1gwdUef4P0uU4gMoT8U",
        authDomain: "monostream-prd.firebaseapp.com",
        projectId: "monostream-prd",
        storageBucket: "monostream-prd.appspot.com",
        messagingSenderId: "1042255967185",
        appId: "1:1042255967185:web:9c96425f3644cca5cf66f0",
        measurementId: "G-9WM0LJ2N95"
      };
      firebase.initializeApp(firebaseConfig);
      const analytics = firebase.analytics();
    }
  });

}( jQuery ));
